import React, { useState } from "react";
import { API_URL } from "../../services/";
import { toast } from "react-toastify";
export default function ProfilePictureUpload(props: any) {
  let [image, setImage] = useState<any>(null);
  const maxSizeInBytes = 1.5 * 1024 * 1024;
  const sendProfilePic = async (baseString: any) => {
    await fetch(`${API_URL}/${props.where}`, {
      method: "POST", // Use POST method
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({ image: baseString }),
    });
  };
  const fileValidation = (event: any) => {
    let file = event.target.files[0];
    if (file.size > maxSizeInBytes) {
      toast.error("File size must be within 1.5mb");
    } else {
      var reader = new FileReader();
      var baseString;
      reader.onloadend = function () {
        baseString = reader.result;
        sendProfilePic(baseString);
        setImage(baseString);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <img
        className="w-[200px] h-[200px] rounded-full"
        src={image ? image : props.profileimage}
        alt="profile pic"
      />
      <input className="mt-10" type="file" onChange={fileValidation} />
    </div>
  );
}
