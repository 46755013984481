import React from "react";

export function ProgressBar({status}) {
  return (
    <div className="relative mb-5 pt-1">
      <div className="flex h-2 overflow-hidden rounded bg-gray-100 text-xs">
        <div style={{ width: `${status}%` }} className="bg-green-500"></div>
      </div>
      <div className="flex items-center justify-end text-xs">
        <div className="text-gray-600">{status ?? 0}%</div>
      </div>
    </div>
  );
}
