import React, { useState, useEffect } from "react";
import type { LearnerFormField } from "../../interfaces/settings.interface";
import { checkValidData } from "./utils/Validate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaUserAlt } from "react-icons/fa";
import axios from "axios";
import { API_URL } from "../../services";
import { useNavigate } from "react-router-dom";
import ProfilePictureUpload from "./ProfilePictureUpdate";
import { toast } from "react-toastify";

export interface FormErrors {
  [key: string]: string;
}

export const LEARNER_FORM_FIELDS: LearnerFormField[] = [
  {
    label: "Email id",
    type: "email",
    value: "email_id",
    required: true,
    restricted: true,
  },
  {
    label: "Learner name",
    type: "text",
    value: "learner_name",
    required: true,
    restricted: false,
  },
  {
    label: "Status",
    type: "radio",
    value: "status",
    required: true,
    restricted: false,
  },
  {
    label: "Date Of Birth",
    type: "date",
    value: "date_of_birth",
    required: true,
    restricted: false,
  },
  {
    label: "Gender",
    type: "select",
    value: "gender",
    required: true,
    restricted: false,
  },
  {
    label: "Telegram Username",
    type: "text",
    value: "telegram_username",
    required: false,
    restricted: false,
  },
  {
    label: "Alternate email",
    type: "email",
    value: "alt_email_id",
    required: false,
    restricted: false,
  },
  {
    label: "Mobile no",
    type: "mobileNo",
    value: "mobile_no",
    required: true,
    restricted: false,
  },
  {
    label: "Course Name",
    type: "text",
    value: "course_name",
    required: true,
    restricted: true,
  },
  {
    label: "Department Name",
    type: "text",
    value: "department",
    required: true,
    restricted: true,
  },
  {
    label: "Department Section",
    type: "text",
    value: "department_section",
    required: true,
    restricted: true,
  },
  {
    label: "Linkedin url",
    type: "url",
    value: "linkedin_url",
    required: false,
    restricted: false,
  },
  {
    label: "Leetcode url",
    type: "url",
    value: "leetcode_url",
    required: false,
    restricted: false,
  },
  {
    label: "Github url",
    type: "url",
    value: "github_url",
    required: false,
    restricted: false,
  },
  {
    label: "college reg no",
    type: "text",
    value: "college_reg_no",
    required: false,
    restricted: false,
  },
  {
    label: "Naukri url",
    type: "url",
    value: "naukri_url",
    required: false,
    restricted: false,
  },
  {
    label: "Tag 1",
    type: "text",
    value: "tag_1",
    required: false,
    restricted: true,
  },
  {
    label: "Tag 2",
    type: "url",
    value: "tag_2",
    required: false,
    restricted: true,
  },
  {
    label: "Valid From",
    type: "date",
    value: "valid_from",
    required: true,
    restricted: true,
  },
  {
    label: "Valid To",
    type: "date",
    value: "valid_to",
    required: true,
    restricted: true,
  },
];

const EditProfile = ({ learner, updateLearner }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({});
  const [profilePic, setProfilePic] = useState<string | undefined>("");
  const [isEditing, setIsEditing] = useState(true);
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    if (learner) {
      console.log("Learner Data:", learner);
      const telegramProfile = learner.social_profiles?.find(profile => profile.name === "telegram");
      setFormData({
        ...learner,
        telegram_username: telegramProfile?.username || ""
      });
      console.log("Telegram Username:", telegramProfile?.username);
      setProfilePic(learner.profile_pic || "");
    }
  }, [learner]);
  
  const UpdateLearnerDetails = async () => {
    const { telegram_username, ...restFormData } = formData;
    const socialProfile = [{ name: "telegram", username: telegram_username }];
    const bodyData = {
      ...restFormData,
      social_profiles: socialProfile,
    };

    try {
      const response = await axios({
        method: "POST",
        url: `${API_URL}/update_learner_details`,
        data: bodyData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        updateLearner(response.data.learner);
        setIsEditing(false);
        localStorage.setItem("user", JSON.stringify(response.data.learner));
      } else {
        console.log(response.data);
      }
    } catch (error) {
      toast.error("Error Updating You Data");
    }
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = checkValidData(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        await UpdateLearnerDetails();
        updateLearner((prevLearner) => ({
          ...prevLearner,
          ...formData,
        }));
        setIsEditing(false);
      } catch (error) {
        console.error("Error updating learner details", error);
      }
    } else {
      toast.error("Form Data is not valid");
      console.log("Form data is not valid");
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="profile_view flex items-center gap-6 mb-8">
        <FaUserAlt className="text-6xl text-gray-600" />
        <div className="flex flex-col">
          <span className="font-bold text-3xl">{formData.learner_name}</span>
          <span className="text-gray-500 text-sm">LMS_ID: {formData._id}</span>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <ProfilePictureUpload profileimage={profilePic} where={"learnerpic"} />

        <form onSubmit={handleSubmit}>
          {LEARNER_FORM_FIELDS.map((field) => (
            <div
              key={field.value}
              className="flex justify-between items-center py-4 border-b border-gray-300"
            >
              <label htmlFor={field.value} className="font-semibold text-gray-700">
                {field.label} {field.required && <span className="text-red-500">*</span>}
              </label>
              <div className="w-2/3">
                {isEditing && !field.restricted ? (
                  <div>
                    {["text", "number", "url", "mobileNo", "email"].includes(field.type) && (
                      <input
                        type={field.type}
                        id={field.value}
                        name={field.value}
                        className="w-full h-10 px-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData[field.value] || ""}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            [field.value]: event.target.value,
                          })
                        }
                      />
                    )}
                    {field.type === "date" && (
                      <DatePicker
                        selected={formData[field.value] ? new Date(formData[field.value]) : null}
                        onChange={(date) => {
                          if (date) {
                            setFormData({
                              ...formData,
                              [field.value]: date.toISOString().split('T')[0],
                            });
                          }
                        }}
                        disabled={field.value === "valid_from"}
                        className="w-full h-10 px-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    )}
                    {field.type === "select" && field.value === "gender" && (
                      <select
                        id={field.value}
                        name={field.value}
                        value={formData[field.value] || ""}
                        className="w-full h-10 px-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-50"
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            [field.value]: event.target.value,
                          })
                        }
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="non-binary">Non-binary</option>
                        <option value="other">Other</option>
                        <option value="prefer-not-to-say">Prefer not to say</option>
                      </select>
                    )}
                    {field.type === "radio" && field.value === "status" && (
                    <div className="flex gap-2">
                      {formData.status ? (
                        <div className="bg-green-500 text-white py-2 px-4 rounded-md">
                          Active
                        </div>
                      ) : (
                        <div className="bg-red-500 text-white py-2 px-4 rounded-md">
                          Inactive
                        </div>
                      )}
                    </div>
                  )}
                  </div>
                ) : (
                  <div>{formData[field.value]}</div>
                )}
                {errors[field.value] && (
                  <div className="text-red-500 text-sm">{errors[field.value]}</div>
                )}
              </div>
            </div>
          ))}

          {isEditing && (
            <div className="flex justify-end mt-4">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Save Changes
              </button>
              <button
                type="button"
                onClick={toggleEdit}
                className="ml-4 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
              >
                Cancel
              </button>
            </div>
          )}

          {!isEditing && (
            <div className="flex justify-end mt-4">
              <button
                type="button"
                onClick={toggleEdit}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Edit
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
