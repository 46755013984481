import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import { API_URL } from "../../services";
import { FaFlag } from "react-icons/fa";
import { Navbar } from "../../Components/Navbar";

const localizer = momentLocalizer(moment);

const BigCalendarComponent: React.FC = () => {
  const [calendarEvents, setCalendarEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCalendarEvents = async () => {
      try {
        const response = await axios.get(`${API_URL}/getCalendarData`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });

        console.log("Fetched calendar events:", response.data);

        const formattedEvents = response.data.map((event: any) => {
          const startDate = moment(event.startDate);
          const endDate = moment(event.endDate);

          if (!startDate.isValid() || !endDate.isValid()) {
            console.warn(`Invalid date detected for event: ${event.title}`);
          }

          return {
            title: event.title,
            start: startDate.isValid() ? startDate.toDate() : new Date(),
            end: endDate.isValid() ? endDate.toDate() : new Date(),
            color: event.isProgram ? "blue" : "gray", // Use gray for assessments
            isAssessment: !event.isProgram, // Flag for assessments
          };
        });

        console.log("Formatted events:", formattedEvents);

        setCalendarEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching calendar events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCalendarEvents();
  }, []);

  const eventStyleGetter = (event: any) => {
    return {
      style: {
        backgroundColor: event.color,
        border: "none", // Remove border to highlight the flag
      },
    };
  };

  const Event = (props: any) => {
    const { event } = props;

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {event.isAssessment && (
          <FaFlag style={{ marginRight: "8px", color: "red" }} />
        )}
        <span>{event.title}</span>
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-center items-center h-screen w-screen">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Calendar
            localizer={localizer}
            events={calendarEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "95%", width: "90%" }}
            className="bg-white p-4 shadow-lg rounded-lg"
            eventPropGetter={eventStyleGetter}
            components={{ event: Event }} // Use custom event component
          />
        )}
      </div>
    </div>
  );
};

export default BigCalendarComponent;
