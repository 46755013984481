import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { API_URL } from "../../services";
import { programSchema } from "../../interfaces/dashboard.interface";
import { toast } from "react-toastify";

dayjs.extend(relativeTime);

interface ActivityData {
  test_name: string;
  // Add other properties if needed
}

interface Activity {
  activity_end_date: string;
  data: ActivityData;
}

interface Module {
  activity_list: Activity[];
}

interface Program {
  program_name: any;
  modules_list: Module[];
}

interface Notification {
  programId: any;
  _id: string;
  name: string;
  description: string;
  seen: boolean;
  createdAt: string;
  createdBy: string;
  adminName: string;
  type: string;
  message: string | ActivityData;
  expiry?: string;
  startDate?: string;
  programStartDate?: string;
}

const App: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [program, setProgramData] = useState<programSchema[]>([]);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${API_URL}/get-notification`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      // Log the received notification data

      const sortedNotifications = response.data.sort(
        (a: Notification, b: Notification) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      setNotifications(sortedNotifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleSeen = async (notification: Notification) => {
    try {
      await axios.post(
        `${API_URL}/seen`,
        { seen: true, _id: notification._id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n._id === notification._id ? { ...n, seen: true } : n
        )
      );
    } catch (error) {
      toast.error(error);
      console.error("Error updating notification:", error);
    }
  };

  const deleteAllNotifications = async () => {
    try {
      await axios.delete(`${API_URL}/clear-all-notifications`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // Clear all notifications from local state
      setNotifications([]);
    } catch (error) {
      toast.error(error);
      console.error("Error clearing notifications:", error);
    }
  };

  const fetchProgram = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/learnerprograms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (!response.ok) {
        toast.error("Error");
        console.error("Error:", response);
        return;
      }

      const result = await response.json();
      setProgramData(result.programs);

      // Check deadlines and create notifications
      checkDeadlines(result.programs);

      return result.programs;
    } catch (error) {
      toast.error(error);
      console.error("Fetch error:", error);
      setProgramData([]);
    }
  }, []);

  const checkDeadlines = (programs: Program[]) => {
    const now = new Date();
    const todayStart = new Date(now.setHours(0, 0, 0, 0)); // Start of today
    const tomorrowEnd = new Date(todayStart);
    tomorrowEnd.setDate(todayStart.getDate() + 1);
    tomorrowEnd.setHours(23, 59, 59, 999); // End of tomorrow

    const deadlineNotifications: Notification[] = [];

    programs.forEach((program) => {
      program.modules_list.forEach((module) => {
        module.activity_list.forEach((activity) => {
          const activityEndDate = new Date(activity.activity_end_date);

          // Check if the activity end date is within the next 24 hours
          if (activityEndDate >= todayStart && activityEndDate <= tomorrowEnd) {
            const notificationMessage = `The deadline is approaching for your test on "${activity.data.test_name}" in the program "${program.program_name}".`;

            deadlineNotifications.push({
              // _id: 'temp-id',
              _id: "temp-id-" + Math.random().toString(36),
              name: "Deadline Alert", // Placeholder name
              description: notificationMessage,
              seen: false, // Default value
              createdAt: now.toISOString(),
              createdBy: "", // Placeholder createdBy
              adminName: "admin_name", // Placeholder adminName
              type: "deadline",
              message: activity.data, // Or any other relevant data
              expiry: activityEndDate.toISOString(),
              programId: undefined,
            });
          }
        });
      });
    });

    // Add new notifications at the beginning
    setNotifications((prev) => [...deadlineNotifications, ...prev]);
  };

  const removeExpiredNotifications = () => {
    const now = new Date();
    const updatedNotifications = notifications.filter((notification) => {
      if (notification.expiry) {
        const expiryDate = new Date(notification.expiry);
        return expiryDate.getTime() > now.getTime();
      }
      return true;
    });
    setNotifications(updatedNotifications);
  };

  useEffect(() => {
    fetchNotifications();
    fetchProgram();
  }, [fetchProgram]);

  useEffect(() => {
    const handleLogout = () => {
      // Remove all notifications from state, including deadline notifications
      setNotifications([]);
    };

    window.addEventListener("beforeunload", handleLogout);
    return () => window.removeEventListener("beforeunload", handleLogout);
  }, []);

  useEffect(() => {
    const interval = setInterval(removeExpiredNotifications, 60000); // Check every minute
    return () => clearInterval(interval);
  }, [notifications]);

  const getTimeAgo = (createdAt: string) => {
    return dayjs(createdAt).fromNow();
  };

  return (
    <div className="flex flex-col items-center p-5">
      <div className="w-full max-w-[1200px] z-10">
        {notifications.length > 0 && (
          <button
            onClick={deleteAllNotifications}
            className="mb-5 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
          >
            Clear All
          </button>
        )}

        {notifications.length === 0 ? (
          <p>No notifications</p>
        ) : (
          notifications.map((notification) => {
            const Sdate = new Date(notification.programId?.program_start_date);

            const formatDate = (date) => {
              const day = String(date.getDate()).padStart(2, "0");
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
              const year = date.getFullYear();

              return `${day}-${month}-${year}`;
            };

            const normalizedSdate = formatDate(Sdate);
            return (
              <div
                key={notification._id}
                onClick={() => handleSeen(notification)}
                className={`cursor-pointer p-4 mb-2 rounded-md ${
                  notification.seen ? "bg-gray-100" : "bg-green-100"
                } shadow-md flex flex-col transition-colors ease-in-out overflow-hidden`}
              >
                {notification.name === "Deadline Alert" ? (
                  <>
                    <div className="text-gray-800 mb-1">
                      {/* A new training program " */}
                      <strong className="text-gray-900">
                        {/* {notification.name} */}
                      </strong>
                      {notification.description}
                    </div>
                    <div className="text-gray-500 text-sm">
                      {getTimeAgo(notification.createdAt)}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-gray-800 mb-1">
                      A new training program "
                      <strong className="text-gray-900">
                        {notification.name}
                      </strong>
                      " is assigned for you which starts on "
                      <strong className="text-gray-900">
                        {/* {dayjs(Sdate).format("MMM D, YYYY")} */}
                        {normalizedSdate}
                      </strong>
                      ".
                    </div>
                    <div className="text-gray-500 text-sm">
                      {getTimeAgo(notification.createdAt)}
                    </div>
                  </>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default App;
