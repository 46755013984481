import React from 'react'
import { Outlet } from 'react-router-dom'
import {Navbar} from './Components/Navbar'
import Footer from './Components/footer'

const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen scroll-auto">
        <Navbar/>
      <div className="flex-grow px-10 py-4 w-full min-h-[82.5vh]">
        <Outlet />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
