function isInRange(currentTimeIsoString, startTimeIsoString, endTimeIsoString) {
  // Parse ISO 8601 strings to Date objects
  const currentTime = new Date(currentTimeIsoString);
  const startTime = new Date(startTimeIsoString);
  const endTime = new Date(endTimeIsoString);

  // Convert times to milliseconds since epoch
  const currentMillis = currentTime.getTime();
  const startMillis = startTime.getTime();
  const endMillis = endTime.getTime();

  // Handle cases where start time is after end time (e.g., overnight hours)
  return currentMillis >= startMillis && currentMillis < endMillis;
}

export default function ButtonLogic(
  remainingAttempts,
  currentDateTime,
  startDate,
  endDate,
  reportDataLength
) {
  const inTimeRange = isInRange(currentDateTime, startDate, endDate);
  const hasNoPastData = reportDataLength === 0;
  const hasRemainingAttempts = remainingAttempts >= 1;
  return {
    // Button will be enabled only if learner has any attempts left and is in window to take test
    disabled: !(inTimeRange && hasRemainingAttempts),
    buttonText: hasNoPastData ? "Start Test" : "Start Re-Test",
    color: "bg-blue-500",
  };
}
