import React from "react";
import { BsFillGridFill } from "react-icons/bs";
import { IoGridOutline } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import { convertToIST } from "../services/utils";
import { Link } from "react-router-dom";
import { programSchema } from "../interfaces/dashboard.interface";
interface DynamicCardsProps {
  data: programSchema;
}

const getStatusText = (progressPercentage, endDate): string => {
  if (new Date() > new Date(endDate)) {
    return "Expired";
  } else {
    if (progressPercentage === 0) {
      return "Yet to Start";
    } else if (progressPercentage === 100) {
      return "Completed";
    } else {
      return "In Progress";
    }
  }
};

export function Dynamicards({ data }: DynamicCardsProps) {
  return (
    <>
      <div className="bg-white border-2 flex flex-col gap-4 p-8 rounded-md shadow-md">
        <div className="program-details flex justify-between">
          <span className="text-2xl font-bold mr-8">{data.program_name}</span>
          <span className="ml-8">
            {getStatusText(data.program_progress, data.program_end_date)}
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
          <div
            className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
            style={{ width: `${data.program_progress}%` }}
          >
            {data.program_progress}%
          </div>
        </div>
        <div className="flex justify-between align-center items-center">
          <div className="flex gap-10 m-2">
            <div className="flex flex-col gap-2">
              <div className="align-center flex flex-row gap-3 justify-between">
                <div className="flex align-center items-center">
                  <FaCalendarAlt />
                </div>
                <div className="flex flex-col items-center">
                  Program Start Date
                </div>
              </div>
              <h2 className="font-medium text-sm flex justify-center">
                {convertToIST(data.program_start_date)}
              </h2>
            </div>

            <div className="flex flex-col gap-2">
              <div className="align-center flex flex-row gap-3 justify-between">
                <div className="flex align-center items-center" key={2}>
                  <FaCalendarAlt />
                </div>
                <div className="flex flex-col items-center">
                  Program End Date
                </div>
              </div>
              <h2 className="font-medium text-sm flex justify-center">
                {convertToIST(data.program_end_date)}
              </h2>
            </div>

            <div className="flex flex-col gap-2">
              <div className="align-center flex flex-row gap-3 justify-between">
                <div className="flex align-center items-center" key={3}>
                  <IoGridOutline />
                </div>
                <div className="flex flex-col items-center">No. of Modules</div>
              </div>
              <h2 className="font-medium text-sm flex justify-center">
                {data.moduleCount}
              </h2>
            </div>

            <div className="flex flex-col gap-2">
              <div className="align-center flex flex-row gap-3 justify-between">
                <div className="flex align-center items-center" key={4}>
                  <BsFillGridFill />
                </div>
                <div className="flex flex-col items-center">
                  Completed Modules
                </div>
              </div>
              <h2 className="font-medium text-sm flex justify-center">
                {data.completedModules}
              </h2>
            </div>
          </div>
          <div className="inline-block">
            <Link to={`/program/${data._id}`}>
              <button
                type="button"
                className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                View
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
