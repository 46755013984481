import React, { useCallback } from "react";
import { useEffect, useState, RefObject } from "react";
import { Link, useParams } from "react-router-dom";
import { programSchema } from "./interfaces/dashboard.interface";
import ModuleContent from "./ModuleContent";
import moment from "moment";
import { API_URL } from "./services";
import { convertToIST } from "./services/utils";
import RichTextEditor from "./Components/RichTextEditor";
import { ProgressBar } from "./Components/ProgressBar";
import { IoArrowBackCircleOutline } from "react-icons/io5";

interface User {
  organization_id: string;
  super_admin: string;
  _id: string;
}

interface ReportData {
  "Project Name": string;
  "Recruiter Name": string;
  "AMCAT ID": number;
  "User Name": string;
  UniqueId: number;
  "Name (Invited)": string;
  "Email id (Invited)": string;
  "Date of birth (Invited)": string;
  "Participant status": string;
  "Schedule Date": string;
  "Participant start date and time": string;
  "Participant completed date and time": string;
  tag1: string;
  tag2: string;
  tag3: string;
  tag4: number;
  tag5: number;
  offFocusCount: number;
  "Report URL": string;
  "Operating System": string;
  "Browser Name": string;
  "Voice Not Matched": string;
  "Participant Consent": string;
  "Printscreen threshold": number;
  "Off Focus threshold": number;
  "Print Screen Count": number;
  "Location Violation": string;
  "Total Inactive Time (in seconds)": number;
  "Reattempt counts": number;
  " Resume Counts": number;
  csJobProfileId: number;
  "Demo Test 1(Score)": number;
  "Demo Test 1 (TimeTaken)": string;
  "Demo Test 1 (NumQuestAttempted)": number;
}

export interface Report {
  _id: string;
  test_id: string;
  super_admin: string;
  organization_id: string;
  program_id: string;
  module_id: string;
  activity_id: string;
  auto_login_url: string;
  learner_id: string;
  report_data: ReportData[];
}

export function DynamicModuleName(props) {
  const { programId } = useParams();
  return (
    <>
      {props.module === false && props.data ? (
        <div className="flex flex-col justify-between">
          <span className="mb-4">
            <button
              type="button"
              className="bg-blue-700 border dark:hover:bg-gray-700 font-medium gap-2 hover:bg-gray-100 inline-flex items-center px-5 py-2.5 rounded-lg text-sm text-white"
            >
              <IoArrowBackCircleOutline size={20} />

              <Link to={`/dashboard`}>Back to Dashboard</Link>
            </button>
          </span>
          <div className="flex items-center justify-between">
            <span
              className="text-2xl font-bold mr-8"
              style={{ color: "#000435" }}
            >
              {props.data.program_name.toUpperCase()}
            </span>
            <span className="font-medium">
              {props.data.program_statistics.program_progress === 0
                ? "Yet to Start"
                : props.data.program_statistics.program_progress === 100
                ? "Completed"
                : "In Progress"}
            </span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-between">
          <span className="mb-4">
            <button
              type="button"
              className="bg-blue-700 border dark:hover:bg-gray-700 font-medium gap-2 hover:bg-gray-100 inline-flex items-center px-5 py-2.5 rounded-lg text-sm text-white"
            >
              <IoArrowBackCircleOutline size={20} />
              <Link to={`/program/${programId}`}>
                Back to {props.programName}
              </Link>
            </button>
          </span>
          <div className="flex items-center justify-between">
            <span
              className="text-2xl font-bold mr-8"
              style={{ color: "#000435" }}
            >
              {props.data.module_name.toUpperCase()}
            </span>
            <span className="font-medium">
              {props.data.module_progress === 0
                ? "Yet to Start"
                : props.data.module_progress === 100
                ? "Completed"
                : "In Progress"}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export function DynamicNotice(props) {
  return (
    <div className="flex flex-col gap-4">
      <span className="text-xl font-bold">
        {!props.module ? "Program" : "Module"} Description
      </span>
      <RichTextEditor
        data={
          !props.module
            ? props.data.program_description
            : props.data.module_description
        }
      />
    </div>
  );
}
export function ProgramContent() {
  const [programData, setProgramData] = useState<programSchema>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { programId, moduleId } = useParams();

  const [reportData, setReportData] = useState<Report[]>([]);

  let [loc] = useState<{ loc: string | null }>({ loc: null });
  // let navigate = useNavigate()
  const fetchProgram = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/learnerprogram`, {
        method: "POST",
        body: JSON.stringify({ program_id: programId }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (!response.ok) {
        return;
      }
      const result = await response.json();
      setProgramData(result.program);
      setIsLoading(false);
      return;
    } catch (error) {
      console.error("Fetch error:", error);
      setIsLoading(false);
      return null;
    }
  }, [programId]);

  const fetchData = useCallback(async () => {
    const userString = localStorage.getItem("user");
    const user: User | null = userString ? JSON.parse(userString) : null;
    const learner_id = user ? user._id : "";
    try {
      const response = await fetch(
        `${API_URL}/learner-report-data?learner_id=${learner_id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      setReportData(data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }, []);

  useEffect(() => {
    fetchProgram();
    fetchData();
  }, [programId, fetchProgram, fetchData]);

  if (isLoading) {
    return (
      <h1 className="text-center text-xl">Fetching the program details ...</h1>
    );
  }

  return (
    <>
      {!moduleId ? (
        <div className="flex flex-col gap-4 m-4 ml-10">
          <DynamicModuleName
            module={false}
            data={programData}
            loc={loc.loc}
          ></DynamicModuleName>
          {programData && (
            <div className="form-container flex gap-5">
              <div className="flex h-fit">
                <p className="font-normal text-xl">Program Start Date:</p>
                <p className="font-normal px-2 text-xl">
                  {convertToIST(programData.program_start_date)}
                </p>
              </div>

              <div className="flex h-fit">
                <p className="font-normal text-xl">Program End Date:</p>
                <p className="font-normal px-2 text-xl">
                  {convertToIST(programData.program_end_date)}
                </p>
              </div>
            </div>
          )}
          <DynamicNotice data={programData}></DynamicNotice>
          <div className="divide-x-4 h-1"></div>
          <DynamicardsMini data={programData} loc={loc.loc}></DynamicardsMini>
          {/* <FeedBack /> */}
        </div>
      ) : (
        <ModuleContent
          key={1}
          programName={programData?.program_name ?? ""}
          module_data={programData?.modules_list.find(
            (module) => module._id === moduleId
          )}
          reportData={reportData.filter(
            (report) => report.module_id === moduleId
          )}
        />
      )}
    </>
  );
}
export function DynamicardsMini(props) {
  const { programId } = useParams();
  return (
    <div className="flex flex-col gap-4">
      <span className="text-xl font-bold">Modules List</span>
      <div className={`gap-10 grid grid-cols-mini_card`}>
        {props.data.modules_list.map((module) => {
          return (
            <React.Fragment key={module._id}>
              <Link to={`/program/${programId}/${module._id}`}>
                <div className="bg-white flex flex-col gap-4 h-70 p-8 shadow-md rounded-md">
                  <div className="program-details">
                    <span
                      className="text-2xl font-bold"
                      style={{ color: "#000435" }}
                    >
                      {module.module_name}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <p className="">
                      Start Date:{convertToIST(module.module_start_date)}
                    </p>
                    <p className="">
                      End Date:{convertToIST(module.module_end_date)}
                    </p>
                  </div>
                  <ProgressBar status={module.module_progress} />
                </div>
              </Link>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
