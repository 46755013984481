import React, { useEffect, useRef, useState } from "react";
import { IVideoStatus } from "./VideoGallery";

interface YoutubeIframeProps {
  setVideoStatus: (data: IVideoStatus) => Promise<void>;
  videoStatus: IVideoStatus;
  id: string;
  url: string;
  onRewind: (rewindFn: () => void) => void;  // Updated prop
}

declare global {
  interface Window {
    YT: any;
    onYouTubeIframeAPIReady: () => void;
  }
}

const youtubeRegex =
  /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

export function YoutubeIframe({
  id,
  url,
  setVideoStatus,
  videoStatus,
  onRewind,  // Added prop
}: YoutubeIframeProps) {
  const playerRef = useRef<any>(null);
  const [playerReady, setPlayerReady] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const match = url.match(youtubeRegex);
  const videoId = match ? match[1] : "";
  const playerId = `player-${id}`

  useEffect(() => {
    if (!videoId) return;

    let player: any;

    const startTracking = () => {
      if (!playerRef.current?.intervalId) {
        playerRef.current.intervalId = setInterval(() => {
          const player = playerRef.current;
          if (
            player &&
            typeof player.getCurrentTime === "function" &&
            typeof player.getDuration === "function"
          ) {
            const duration = player.getDuration();
            const currentTime = player.getCurrentTime();
            if (duration > 0) {
              const percentWatched = (currentTime / duration) * 100;
              if (percentWatched >= 90 && !player.completed) {
                player.completed = true;
                setVideoStatus({
                  progress: percentWatched,
                  status: "Completed",
                });
              } else if (
                percentWatched < 90 &&
                videoStatus.status !== "In Progress"
              ) {
                setVideoStatus({
                  progress: percentWatched,
                  status: "In Progress",
                });
              }
            }
          }
        }, 1000);
      }
    };

    const onPlayerStateChange = (event: any) => {
      if (event.data === window.YT.PlayerState.PLAYING) {
        startTracking();
      } else if (
        event.data === window.YT.PlayerState.ENDED ||
        event.data === window.YT.PlayerState.PAUSED
      ) {
        stopTracking();
      }
    };

    const loadYouTubeAPI = () => {
      return new Promise<void>((resolve) => {
        if (window.YT && typeof window.YT.Player === "function") {
          resolve();
        } else {
          const tag = document.createElement("script");
          tag.src = "https://www.youtube.com/iframe_api";
          const firstScriptTag = document.getElementsByTagName("script")[0];
          firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
          window.onYouTubeIframeAPIReady = resolve;
        }
      });
    };

    const initializePlayer = async () => {
      await loadYouTubeAPI();

      player = new window.YT.Player(playerId, {
        height: "100%",
        width: "100%",
        videoId,
        playerVars: {
          autoplay: 0,
          controls: 0,
          modestbranding: 1,
          fs: 0,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          disablekb: 1,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
          onError: onPlayerError,
        },
      });

      playerRef.current = player;
    };

    const rewindVideo = () => {
      const player = playerRef.current;
      if (player && typeof player.getCurrentTime === "function") {
        const currentTime = player.getCurrentTime();
        player.seekTo(Math.max(0, currentTime - 10), true); // Rewind by 10 seconds, but not below 0
      }
    };

    initializePlayer().catch((err) =>
      setError("Failed to initialize YouTube player")
    );

    // Expose the rewind method
    onRewind(rewindVideo);

    return () => {
      if (player && typeof player.destroy === "function") {
        player.destroy();
      }
    };
  }, [setVideoStatus, videoId, videoStatus.status, onRewind]);

  const onPlayerReady = (event: any) => {
    console.log("YouTube Player is ready.");
    setPlayerReady(true);
  };

  const onPlayerError = (event: any) => {
    setError(`YouTube player error: ${event.data}`);
  };

  const stopTracking = () => {
    if (playerRef.current?.intervalId) {
      clearInterval(playerRef.current.intervalId);
      playerRef.current.intervalId = null;
    }
  };

  const handlePlayPause = () => {
    const player = playerRef.current;
    if (player && playerReady && typeof player.getPlayerState === "function") {
      if (player.getPlayerState() === window.YT.PlayerState.PLAYING) {
        player.pauseVideo();
        setIsPlaying(false);
      } else {
        player.playVideo();
        setIsPlaying(true);
      }
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="relative" style={{ width: "100%", paddingTop: "56.25%" }}>
      <div
        id={playerId}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></div>
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-200">
          <div
            className="bg-green-500 h-full"
            style={{ width: `${videoStatus.progress}%` }}
          ></div>
        </div>
      </div>
      <div 
        className="absolute inset-0 flex items-center justify-center"
        style={{ backgroundColor: 'rgba(0,0,0,0)' }}
        onClick={handlePlayPause}
      >
        {!isPlaying && (
          <button className="text-white text-6xl">▶</button>
        )}
      </div>
    </div>
  );
}
