import axios from "axios";
import { API_URL } from ".";

export const generateUrlAndReport = async function (data) {
  const payload = {
    activity_id: data.activity_id,
    email_id: data.email_id,
    learner_id: data.learner_id,
    learner_name: data.learner_name,
    module_id: data.module_id,
    organization_id: data.organization_id,
    program_id: data.program_id,
    super_admin: data.super_admin,
    test_id: data.test_id,
  };
  return await axios.post(`${API_URL}/create-report`, payload, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
