import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

interface RichTextEditorProps {
  data: string; // Assuming data is of type string
  height?: number; // Optional height prop
  className?: string; // Optional className prop
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ data }) => {
  const [code, setCode] = useState<string>(data ?? "");

  const modules = {
    toolbar: false,
  };

  useEffect(() => {
    setCode(data);
  }, [data]);
  return (
    <ReactQuill
      className="bg-white"
      readOnly={true}
      theme="snow"
      value={code}
      modules={modules}
      formats={[]}
    />
  );
};

export default RichTextEditor;
