import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ANALYTICS_API_URL } from "../../services";
import userData from "../../hooks/useData";
import PieChart1 from "./PieChart1";
import "../../styles/Piechart.css";
import AssessmentPerformanceChart from "./Assessment";
import RadioButton from "./RadioButton";
import { SortChartType } from "./report.interface";

export const defaultBarLineChartData = {
  labels: [],
  campus_percentile: [],
  data: [],
  overall_percentile: [],
};

export interface IChartData {
  labels: string[];
  series: number[];
}
interface ILearnerAnalyticsResponse {
  program_status: IChartData;
  assessment_status: IChartData;
  assessment_performance: {};
}

const LearnerAnalytics = () => {
  const { user } = userData();
  const [selected, setSelected] = useState<SortChartType>("average");

  const [data, setData] = useState<ILearnerAnalyticsResponse>({
    program_status: { labels: [], series: [] },
    assessment_status: { labels: [], series: [] },
    assessment_performance: {
      average: defaultBarLineChartData,
      max: defaultBarLineChartData,
      latest: defaultBarLineChartData,
    },
  });

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(
        `${ANALYTICS_API_URL}/learner_level_response?learner_id=${user?._id}`
      );
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error("Error fetching learner data:", error);
    }
  }, [user._id]);

  useEffect(() => {
    if (user && user._id) {
      void fetchData();
    }
  }, [fetchData, user, user._id]);

  // Extract the labels and series based on the selected value (avg, max, latest)
  const assessmentData = useMemo(() => {
    console.log(data.assessment_performance[selected]);
    return data.assessment_performance[selected];
  }, [selected, data]);

  return (
    <div className="flex flex-col items-center justify-center gap-7">
      <h1 className="mb-4">Learner Analytics</h1>

      <div className="chart-container">
        <div className="chart-item">
          <PieChart1
            header_data={"Program Status"}
            data={data.program_status}
          />
        </div>
        <div className="chart-item">
          <PieChart1
            header_data={"Assessment Status"}
            data={data.assessment_status}
          />
        </div>
      </div>
      <RadioButton selected={selected} handleChange={setSelected} />
      <AssessmentPerformanceChart
        title="Assessment Performance"
        data={assessmentData}
      />
    </div>
  );
};

export default LearnerAnalytics;
