import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { ToastProvider } from './ToastProvider'
import Dashboard from './Dashboard'
import { DynamicNotice, ProgramContent } from './ProgramContent'
import Profile from './pages/Profile/profile'
import Settings from './pages/Profile/settings'
import AnalyticsDashboard from './pages/Analytics'
import LearnerAnalytics from './pages/Analytics/LearnerAnalytics'
import Notifications from './Components/notification/notification'
import CalendarComponent from './Components/calendar/calendar'
import Layout from './layout'
import App from './pages/Login/login'
import 'react-toastify/dist/ReactToastify.css'

const AppRoutes = () => {
  return (
    <ToastProvider>
  <ToastContainer />
  <Routes>
    <Route path="/" element={<App />} />
    <Route element={<Layout />}>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/program/:programId" element={<ProgramContent />}>
        <Route path=":moduleId" />
      </Route>
      <Route path="/dynamic" element={<DynamicNotice />} />
      <Route path="/analytics" element={<AnalyticsDashboard />}>
        <Route index element={<LearnerAnalytics />} />
      </Route>
      <Route path="/settings" element={<Settings />}>
        <Route path="editProfile" element={<Profile />} />
        <Route path="profile" element={<Profile />} />
      </Route>
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/calendar" element={<CalendarComponent />} />
    </Route>
  </Routes>
</ToastProvider>

  )
}

export default AppRoutes
