import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../services";
import userData from "../hooks/useData";
import { useParams } from "react-router-dom";
import { type ProgressType } from "../interfaces/progress.interface";
import { YoutubeIframe } from "./YoutubeIframe";

interface ActivityData {
  activity_url?: string;
  activity_name?: string;
  activity_description?: string;
}

interface VideoGalleryProps {
  activity: {
    data: ActivityData;
    _id: string;
  };
}

export interface IVideoStatus {
  progress: number;
  status: ProgressType;
}

export function VideoGallery({ activity }: VideoGalleryProps) {
  const { user } = userData();
  const { super_admin, organization_id, _id: learner_id } = user as {
    super_admin: string;
    organization_id: string;
    _id: string;
  };
  const { programId, moduleId } = useParams();
  const [rewindVideo, setRewindVideo] = useState<() => void>(() => {});
  const [videoStatus, setVideoStatus] = useState<IVideoStatus>({
    progress: 0,
    status: "Yet to Start",
  });

  const url = activity.data?.activity_url || "";

  const saveProgress = useCallback(
    async ({ progress, status }: IVideoStatus) => {
      try {
        const response = await fetch(`${API_URL}/save-video-progress`, {
          body: JSON.stringify({
            superAdminId: super_admin,
            organizationId: organization_id,
            learnerId: learner_id,
            program_id: programId,
            module_id: moduleId,
            activity_id: activity._id,
            progressValue: progress,
            statusValue: status,
          }),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        });
        console.log("Progress saved:", response);
      } catch (err) {
        console.error("Failed to save progress:", err);
      }
    },
    [super_admin, organization_id, learner_id, programId, moduleId, activity._id]
  );

  const fetchProgress = useCallback(async () => {
    try {
      const response = await axios.post(`${API_URL}/fetch-video-progress`, {
        superAdminId: super_admin,
        organizationId: organization_id,
        learnerId: learner_id,
        program_id: programId,
        module_id: moduleId,
        activity_id: activity._id,
      });
      const { progress, status } = response.data;
      setVideoStatus({ progress, status });
    } catch (err) {
      console.error("Failed to fetch progress:", err);
    }
  }, [activity._id, learner_id, moduleId, organization_id, programId, super_admin]);

  // useEffect(() => {
  //   void fetchProgress();
  // }, [fetchProgress]);

  // Callback to set rewindVideo function, ensuring it only runs once
  const handleRewindSetup = useCallback((rewindFn: () => void) => {
    setRewindVideo(() => rewindFn);
  }, []);

  if (!url) {
    return (
      <div className="w-[600px] h-[290px] border-[1.5px] border-[#000] flex items-center justify-center translate-y-20 ml-10">
        Invalid video URL.
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 bg-white border-2 border-[1px] p-8 rounded shadow-md">
      <h1 className="font-bold">{activity.data?.activity_name ?? ""}</h1>
      <YoutubeIframe
        id={activity._id}
        setVideoStatus={saveProgress}
        videoStatus={videoStatus}
        url={url}
        onRewind={handleRewindSetup}
      />
      <button onClick={() => rewindVideo()} className="mt-4 p-2 bg-blue-500 text-white rounded">
        Rewind 10 Seconds
      </button>
      <div>
        {activity.data?.activity_description?.includes("</") ? (
          <div
            dangerouslySetInnerHTML={{
              __html: activity.data.activity_description?.replace(
                /(<? *script)/gi,
                "illegalscript"
              ),
            }}
          ></div>
        ) : (
          activity.data?.activity_description
        )}
      </div>
    </div>
  );
}
