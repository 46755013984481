import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import userData from "../hooks/useData";
import { API_URL } from "../services/index";
import defaultLogo from "../assets/images/sflLogo.jpg";
import Notifications from "./notification/notification";
import { FaBell, FaCalendarAlt } from "react-icons/fa";
import axios from "axios";

interface ImageFetcherProps {
  createdById: string;
}

interface Notification {
  _id: string;
  name: string;
  description: string;
  seen: boolean;
  createdAt: string;
  createdBy: string;
  adminName: string;
}

interface ImageFetcherProps {
  createdById: string;
}

const ImageFetcher: React.FC<ImageFetcherProps> = ({ createdById }) => {
  const [currentLogo, setCurrentLogo] = useState<string | null>(defaultLogo);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/get-uploads?created_by=${createdById}`
        );

        const data = response.data;
        const favicon = data.data.favicon;

        // Set favicon if available
        if (favicon) {
          let link = document.querySelector(
            "link[rel='icon']"
          ) as HTMLLinkElement;
          if (!link) {
            link = document.createElement("link") as HTMLLinkElement;
            link.rel = "icon";
            document.head.appendChild(link);
          }

          link.type = "image/x-icon";
          link.href = favicon;
        }

        // Set logo if available
        if (data && data.data && data.data.logo) {
          setCurrentLogo(data.data.logo);
        } else {
          setCurrentLogo(defaultLogo);
        }
      } catch (err: any) {
        console.error("Error fetching logo:", err);
        setCurrentLogo(defaultLogo);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [createdById]);

  if (loading) return <p>Loading...</p>;

  return (
    <img
      src={currentLogo || defaultLogo}
      alt="Company Logo"
      className="ml-10  h-[4.5rem] w-auto"
    />
  );
};

export function Navbar() {
  const destination = window.location.pathname;
  const { user, removeUser, token } = userData();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationDropdownOpen, setNotificationDropdownOpen] =
    useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const notificationDropdownRef = useRef<HTMLDivElement>(null);

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${API_URL}/get-notification`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setNotifications(response.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchUserDataHandler = useCallback(async () => {
    if (token) {
      const expiryDate = localStorage.getItem("expiryDate")!;
      if (new Date(expiryDate) <= new Date()) {
        removeUser();
        return;
      }
    }
  }, [removeUser, token]);

  useEffect(() => {
    fetchUserDataHandler();
  }, [fetchUserDataHandler]);

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleNotificationClick = () => {
    setNotificationDropdownOpen(!notificationDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
    if (
      notificationDropdownRef.current &&
      !notificationDropdownRef.current.contains(event.target as Node)
    ) {
      setNotificationDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const unseenNotifications = notifications.filter(
    (notification) => !notification.seen
  ).length;

  return (
    <div className="strap w-full bg-btn-suc h-20 text-white flex justify-between align-center">
      <div className="brand_logo flex align-center justify-center flex-col ml-10">
        <Link to="/dashboard" className="flex items-center">
          <ImageFetcher
            createdById={user?.super_admin ? user.super_admin : user?._id || ""}
          />
        </Link>
      </div>
      <div className="list-items flex items-center justify-center mr-10">
        <ul className="flex justify-around gap-7 font-medium">
          <li className="relative flex items-center">
            <Link to="/calendar">
              <button className="flex items-center relative ">
                <FaCalendarAlt className="text-xl" />
              </button>
            </Link>
          </li>
          <li
            className="relative flex items-center"
            ref={notificationDropdownRef}
          >
            <button
              className="flex items-center relative"
              onClick={handleNotificationClick}
            >
              <FaBell className="text-xl" />
              {unseenNotifications > 0 && (
                <span className="ml-1 absolute top-1 right-[-0.6rem] rounded-full bg-red-500 w-5 h-5 flex items-center justify-center text-xs text-white">
                  {unseenNotifications}
                </span>
              )}
            </button>
            <div
              className={`absolute top-full mt-2 w-[30rem] bg-white rounded-md shadow-sm py-1 text-gray-700 z-10 ${
                notificationDropdownOpen ? "" : "hidden"
              }`}
            >
              <Notifications />
            </div>
          </li>
          <li
            className={`flex items-center justify-center w-auto h-auto px-3 ${
              destination === "/dashboard" ? "bg-overlay-select" : ""
            }`}
          >
            <Link to="/dashboard">My Program</Link>
          </li>

          <li
            className={`flex items-center justify-center w-auto h-auto px-3 ${
              destination === "/analytics" ? "bg-overlay-select" : ""
            }`}
          >
            <Link to="/analytics">ANALYTICS</Link>
          </li>
          <div className="relative" ref={dropdownRef}>
            <button
              className="flex items-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white gap-2"
              onClick={handleDropdownClick}
            >
              <span className="text-white hidden md:block">
                {user?.learner_name}
              </span>
              <img
                className="h-12 w-12 rounded-full object-cover mr-2"
                src={user?.profile_pic || "https://placehold.co/60x40"}
                alt="Profile"
              />
            </button>
            {dropdownOpen && (
              <div className="absolute top-full mt-2 w-48 bg-white rounded-md shadow-sm py-1 text-gray-700">
                <a
                  href="/settings/editProfile"
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Global Settings
                </a>
                <button
                  onClick={removeUser}
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </ul>
      </div>
    </div>
  );
}
