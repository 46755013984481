import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const useUserData = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState<any>('')
  const [token, setToken] = useState('')

  useEffect(() => {
    const storedUser = localStorage.getItem('user')
    const storedToken = localStorage.getItem('token')
    setToken(storedToken ? storedToken : '')
    setUser(storedUser ? JSON.parse(storedUser) : null)
  }, [])

  const storeData = (userData: any, tokenData: string) => {
    localStorage.setItem('user', JSON.stringify(userData))
    localStorage.setItem('token', tokenData)
    const expiryDate = new Date(
      new Date().getTime() + 7 * 24 * 60 * 60 * 1000
    );
    localStorage.setItem("expiryDate", expiryDate.toISOString());
    setUser(userData)
  }

  const removeUser = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('expiryDate')
    setToken('')
    setUser('')
    navigate('/')
  }

  return { user, token, storeData, removeUser }
}

export default useUserData
