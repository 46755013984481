import React, { useState } from "react";
import axios from "axios";
import { FaRegUser } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import useUserData from "../../hooks/useData";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../services";
import { Organization } from "../../interfaces/settings.interface";
import OtpInput from "./OtpInput";

const Login = () => {
  const navigate = useNavigate();
  const { storeData } = useUserData();
  const [log, changeLog] = useState({
    email: "",
    password: "",
    organization: "",
  });
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showOTPpopup, setShowOTPpopup] = useState(false);
  const [showUpdatePassword, setshowUpdatePassword] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [newpassword, setnewpassword] = useState("");

  const handleChange = (event: any) => {
    changeLog({ ...log, [event.target.name]: event.target.value });
  };

  const handleForgotPasswordClick = () => {
    setShowResetPassword(true);
  };

  const handleBackToLoginClick = () => {
    setShowResetPassword(false);
  };

  const handleSendEmail = async (e: any) => {
    e.preventDefault();
    console.log("Sending email");
    try {
      const response = await axios.post(
        `${API_URL}/learner_resetPassword`,
        { email: log.email },
        { withCredentials: true }
      );
      const data = response.data;
      if (data.error) {
        toast.error(data.error);
        seterrorMessage(data.error);
      } else {
        toast.success("Email sent Sucessfully");
        seterrorMessage("");
      }
      setShowOTPpopup(true);
      setShowResetPassword(false);
    } catch (err: any) {
      toast.error(err.response.data.error);
      console.error("Error sending email:", err);
      seterrorMessage("Error sending email");
    }
  };

  const handleOTPsubmit = async (otpValue: string) => {
    try {
      setOtp(otpValue);
      const response = await axios.post(
        `${API_URL}/learner_validateOTP`,
        { email: log.email, otp, organization_id: log.organization },
        { withCredentials: true }
      );
      const data = response.data;
      console.log(data);
      if (data.error) {
        toast.error(data.error);
        seterrorMessage(data.error);
      } else {
        toast.success("Valid OTP");
        seterrorMessage("");
      }
      setShowOTPpopup(false);
      setShowResetPassword(false);
      setshowUpdatePassword(true);
    } catch (error: any) {
      toast.error(error);
      console.error("Error validating OTP:", error);
      seterrorMessage("Error validating OTP");
    }
  };

  const handleOTPback = () => {
    setShowOTPpopup(false);
    setShowResetPassword(true);
  };

  const handleUpdatePassword = async () => {
    try {
      console.log(newpassword);
      await axios.post(`${API_URL}/learner_updatePassword`, {
        email: log.email,
        password: newpassword,
        organization_id: log.organization,
      });
      toast.success("Password Updated Sucessfully");
      navigate("/");
    } catch (error) {
      toast.error("Error in updating the password");
      console.error("Error updating password", error);
      seterrorMessage("Error updating Password");
    }
  };

  const handlelogin = async (e: any) => {
    e.preventDefault();

    const isValidPassword = (password) =>
      typeof password === "string" &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[@$!%*?&]/.test(password) &&
      password.length >= 8;

    const isPasswordValid = isValidPassword(log.password);

    if (!isPasswordValid) {
      seterrorMessage(
        "Password must be at least 8 characters long and contain at least one digit, one lowercase letter, one uppercase letter, and one special character."
      );
      toast.error(
        "Password must be 8+ chars, with a digit, lowercase, uppercase, and a special character."
      );
      return; // Exit the function if the password is invalid
    }

    try {
      const response = await fetch(`${API_URL}/learnerauth`, {
        method: "POST",
        body: JSON.stringify({
          username: log.email,
          password: log.password,
          organization_id: log.organization,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const result = await response.json();
        toast.success("Login Successful");
        storeData(result.user_data, result.token);
        navigate("/dashboard");
      } else {
        const errorData = await response.json();
        toast.error(
          errorData.error || "Login failed. Please check your credentials."
        );
      }
    } catch (error: any) {
      toast.error(
        error.message || "An unexpected error occurred during login."
      );
      console.error("Error logging in:", error);
    }
  };

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const handleOrganization = async () => {
    try {
      const response = await axios(`${API_URL}/getOrganizationData`, {
        method: "POST", // Use POST method
        data: { username: log.email, role: "learner" },
      });
      const data = response.data;
      console.log(data.data.length);
      if (data && data.data.length !== 0) {
        console.log(data);
        setOrganizations(data.data);
      } else {
        if (data.data.length === 0) {
          toast.warning("Learner not registered");
        }
        setOrganizations([]);
      }

      console.log(response.data); // Assuming the response contains an array of organizations
    } catch (error: any) {
      console.error("Error occurred while fetching organization data:", error);
      if (error.response) {
        // The request was made, and the server responded with a status code
        console.log("Response error:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.log("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Axios error:", error.message);
      }
    }
  };
  return (
    <div
      style={{ height: "100vh" }}
      className="bg-cover bg-hero-pattern  box-border flex flex-col items-center justify-center h-vh-screen"
    >
      <div className="p-10 bg-white text-center rounded-lg w-[600px]">
        {!showResetPassword && !showUpdatePassword && !showOTPpopup ? (
          <div>
            <div className="flex items-center w-full justify-center ">
              <div className="bg-client-logo bg-cover h-32 mb-5 w-11/12"></div>
            </div>
            <div className="mb-4 relative">
              <div className="flex items-center border border-gray-700 rounded">
                <span className="mx-1 text-gray-900">
                  <FaRegUser />
                </span>
                <input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="ENTER EMAIL"
                  value={log.email}
                  required
                  onChange={handleChange}
                  className="font-normal bg-gray-100
                                                          block w-full rounded-md border-0 px-3 py-2 
                                                          text-gray-900 shadow-sm placeholder:text-gray-400 
                                                           sm:text-sm sm:leading-6 focus:shadow-lg"
                />
              </div>
            </div>
            <div className="mb-4 relative">
              {log.organization && log.organization !== "" && (
                <div className="flex items-center border border-gray-700 rounded">
                  <span className="mx-1 text-gray-900">
                    <RiLockPasswordLine />
                  </span>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={log.password}
                    placeholder="PASSWORD"
                    required
                    className="font-normal bg-gray-100 block w-full 
                                                          rounded-md border-0 px-3 py-2 text-gray-900 
                                                          shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
              )}
            </div>
            <div className="mb-4 relative">
              {organizations && organizations.length > 0 && (
                <div className="flex items-center border border-gray-700 rounded">
                  <span className="mx-1 text-gray-900">
                    <RiLockPasswordLine />
                  </span>

                  <select
                    id="organization"
                    name="organization"
                    aria-label="organization" // Add this aria-label attribute
                    value={log.organization}
                    className="font-normal bg-gray-100 block w-full 
                    rounded-md border-0 px-3 py-2 text-gray-900 
                    shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    onChange={handleChange}
                  >
                    <option value="">~SELECT~</option>
                    {organizations.map((org) => (
                      <option key={org?._id} value={org?._id}>
                        {org.super_admin} - {org.organization_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {log.email && (
              <button
                onClick={handleOrganization}
                className={`bg-yellow-600 mb-5 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm 
        ${
          !log.email
            ? "disabled:bg-gray-400"
            : "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
        }`}
              >
                Get Organization
              </button>
            )}
            <button
              disabled={!log.email || !log.password || !log.organization}
              onClick={handlelogin}
              className={`bg-blue-500 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm 
                    ${
                      !log.email || !log.password || !log.organization
                        ? "disabled:bg-gray-400"
                        : "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }`}
            >
              LOGIN
            </button>
            <div className="flex justify-end mt-4">
              <a
                href="#"
                onClick={handleForgotPasswordClick}
                className="block mt-2 hover:underline"
              >
                FORGET PASSWORD?
              </a>
            </div>
          </div>
        ) : !showUpdatePassword ? (
          <>
            <h2 className="text-3xl font-medium mb-4">RESET PASSWORD</h2>
            <div className="mb-4">
              <div className="flex items-center border border-gray-700 rounded">
                <span className="mx-1 text-gray-900">
                  <FaRegUser />
                </span>
                <input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="ENTER EMAIL"
                  value={log.email}
                  required
                  onChange={handleChange}
                  className="font-normal bg-gray-100
                                                          block w-full rounded-md border-0 px-3 py-2 
                                                          text-gray-900 shadow-sm placeholder:text-gray-400 
                                                           sm:text-sm sm:leading-6 focus:shadow-lg"
                />
              </div>
              <div className="mb-4 relative">
                {organizations && organizations.length > 0 && (
                  <div className="flex items-center border border-gray-700 rounded">
                    <span className="mx-1 text-gray-900">
                      <RiLockPasswordLine />
                    </span>

                    <select
                      id="organization"
                      name="organization"
                      aria-label="organization" // Add this aria-label attribute
                      value={log.organization}
                      className="font-normal bg-gray-100 block w-full 
                    rounded-md border-0 px-3 py-2 text-gray-900 
                    shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      onChange={handleChange}
                    >
                      <option value="">~SELECT~</option>
                      {organizations.map((org) => (
                        <option key={org?._id} value={org?._id}>
                          {org.super_admin} - {org.organization_name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              {log.email && (
                <button
                  onClick={handleOrganization}
                  className={`bg-yellow-600 mb-5 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm 
        ${
          !log.email
            ? "disabled:bg-gray-400"
            : "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
        }`}
                >
                  Get Organization
                </button>
              )}
            </div>
            <button
              disabled={!log.email || !log.organization}
              onClick={(e) => {
                handleSendEmail(e);
              }}
              className="w-full bg-green-700 text-white py-2 px-4 rounded hover:bg-green-800 text-xl focus:outline-none"
            >
              SEND EMAIL
            </button>
            <div className="flex justify-end">
              <button
                onClick={handleBackToLoginClick}
                className="block mt-4 hover:underline"
              >
                BACK TO LOGIN
              </button>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-3xl font-medium mb-4">Enter Email</h2>
            <div className="mb-4">
              <input
                type="email"
                id="email"
                name="email"
                disabled
                placeholder="Enter Email"
                className="w-full px-4 py-2 border border-gray-700 rounded focus:outline-none focus:border-blue-400"
                value={log.email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <select
                id="organization"
                name="organization"
                aria-label="organization" // Add this aria-label attribute
                value={log.organization}
                disabled
                className="font-normal bg-gray-100 block w-full 
                    rounded-md border-0 px-3 py-2 text-gray-900 
                    shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                onChange={handleChange}
              >
                <option value="">~SELECT~</option>
                {organizations.map((org) => (
                  <option key={org?._id} value={org?._id}>
                    {org.super_admin} - {org.organization_name}
                  </option>
                ))}
              </select>
            </div>
            <h2 className="text-3xl font-medium mb-4">ENTER NEW PASSWORD</h2>
            <div className="mb-4">
              <input
                onChange={(e) => setnewpassword(e.target.value)}
                type="password"
                id="resetpassword"
                name="password"
                placeholder="Enter Password"
                className="w-full px-4 py-2 border border-gray-700 rounded"
              />
            </div>

            <button
              onClick={handleUpdatePassword}
              className="w-full bg-green-700 text-white py-2 px-4 rounded hover:bg-green-800 text-xl"
            >
              UPDATE PASSWORD
            </button>
          </>
        )}
      </div>
      {showOTPpopup && (
        <OtpInput
          numInputs={6}
          handlesubmit={handleOTPsubmit}
          handleback={handleOTPback}
          errorMessage={errorMessage}
          email={email}
        />
      )}
    </div>
  );
};

export default Login;
