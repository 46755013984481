import React from "react";
import {
  DynamicModuleName,
  DynamicNotice,
  type Report,
} from "./ProgramContent";
import "react-datepicker/dist/react-datepicker.css";
import AssessmentReport from "./Components/AssessmentReport";
import { VideoGallery } from "./Components/VideoGallery";
import { convertToIST } from "./services/utils";

interface IModuleContentProps {
  programName: string;
  module_data: any;
  reportData: Report[];
}

export default function ModuleContent({
  programName,
  module_data,
  reportData,
}: IModuleContentProps) {
  const reports = [...reportData];

  return (
    <div className="flex flex-col gap-4 m-4 ml-10">
      <DynamicModuleName
        data={module_data}
        programName={programName}
        module={true}
      />
      <div className="form-container flex gap-5">
        <div className="flex h-fit">
          <p className="font-normal ">Module Start Date:</p>
          <p className="font-normal px-2">
            {convertToIST(module_data.module_start_date)}
          </p>
        </div>
        <div className="flex h-fit">
          <p className="font-normal ">Module End Date:</p>
          <p className="font-normal px-2">
            {convertToIST(module_data.module_end_date)}
          </p>
        </div>
      </div>
      <DynamicNotice data={module_data} module={true} />
      <span className="text-xl">Events List</span>
      <div className="flex flex-col gap-8">
        {module_data.activity_list.map((activity) => {
          if (activity.type === "video") {
            return <VideoGallery activity={activity} key={activity._id} />;
          } else if (activity.type === "assessment") {
            let matchedReport = reports.find(
              (report) => report.activity_id === activity._id
            ) || { report_data: [], auto_login_url: "" };

            return (
              <AssessmentReport
                key={activity._id}
                activity={activity}
                report={matchedReport}
              />
            );
          }

          return <div key={activity._id}></div>;
        })}
      </div>
    </div>
  );
}
