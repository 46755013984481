import React from 'react';
import Chart from 'react-apexcharts';
import "../../styles/Piechart.css";
import { IChartData } from './LearnerAnalytics';

interface PieChart1Props{
  header_data :string,
   data : IChartData 
}

const PieChart1 = ({
  header_data = "Default Header",
  data 
} :PieChart1Props) => {
  const options = {
    chart: {
      id: 'apexchart-example',
      type: 'pie',
    },
    labels: data.labels || [],
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
  };

  const series = data.series || [];

  return (
    <div className="pie-chart-container">
      <h3>{header_data}</h3>
      <div className='bg-black w-full h-[1px] my-2'></div>
      {series.length > 0 && options.labels.length > 0 ? (
        <Chart 
          options={options} 
          series={series} 
          type="pie" 
          width={400} 
          height={300} 
        />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default PieChart1;
