import { LEARNER_FORM_FIELDS } from '../EditProfile'
import { FormErrors } from '../EditProfile'

export const checkValidData = (formData: any): FormErrors => {
  const error: FormErrors = {}

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const mobileRegex = /^[0-9]{10}$/
  const urlRegex = /^(https?:\/\/)?([\w\d-]+\.)+[\w\d]{2,}(\/.*)*$/
  const textRegex = /^[a-zA-Z0-9\s,'.()-]+$/
  const validFrom = formData['valid_from']
  const validTo = formData['valid_to']

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  if (validFrom && new Date(validFrom) > new Date(validTo)) {
    error['valid_from'] = 'Please select a start date lesser than the end date'
  }

  if (validFrom && validTo && new Date(validTo) < new Date(validFrom)) {
    error['valid_to'] = 'Valid To date must be greater than Valid From date'
  }

  for (const field of LEARNER_FORM_FIELDS) {
    if (field.required && !formData[field.value]) {
      error[field.value] = `${field.label} should not be empty`
    }

    if (formData[field.value]) {
      switch (field.type) {
        case 'email':
          if (!emailRegex.test(formData[field.value])) {
            error[field.value] = 'Email is not valid'
          }
          break
        case 'mobileNo':
          const fieldValueAsString = formData[field.value].toString() // Convert to string
          if (!mobileRegex.test(fieldValueAsString)) {
            error[field.value] = 'Mobile number is not valid'
          }
          break
        case 'text':
          if (!textRegex.test(formData[field.value])) {
            console.log(formData[field.value])
            error[field.value] = `${field.label} is not valid`
          }
          break
        case 'url':
          if (!urlRegex.test(formData[field.value])) {
            error[field.value] = 'URL is not valid'
          }
          break
        default:
          break
      }
    }
  }

  return error
}
