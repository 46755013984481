import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import CustomSideBar from "./CustomSideBar";

const Settings = () => {
  return (
    <div>
      <div>
        {/* <CustomSideBar /> */}
      </div>
      <div className="flex items-center justify-center">
        <div className="">
          {/* <SideNav /> */}
        </div>
        <div className="w-[90%] px-5 py-2">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Settings;

const SideNav = () => {
  const [activePath, setActivePath] = useState("");
  const location = useLocation();

  const childNavData = [
    { path: '/settings/editProfile', linkText: 'Profile' },
  ]

  React.useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  return (
    <div className="">
      {childNavData && (
        <div className="pb-40 pt-2 bg-white border-solid border-2 rounded-md border-gray-400 ">
          {childNavData.map((navItem) => (
            <div
              key={navItem.path}
              className={`text-center text-lg p-2 cursor-pointer ${
                activePath.startsWith(navItem.path)
                  ? "bg-gray-300"
                  : "hover:bg-gray-300"
              }`}
            >
              <Link to={navItem.path}>{navItem.linkText}</Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
