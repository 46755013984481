import React from "react";
import { useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import EditProfile from "./EditProfile";
import { API_URL } from "../../services";
import { toast } from "react-toastify";

let handleSubmit = function (value, id, navigate) {
  console.log(value, id, navigate);
  // fetchUtil("http://localhost/setlearner_data",JSON.stringify({[id]:value}),CONTENT_TYPE,"include",()=>{},navigate,()=>{}).then(()=>{
  //    navigate("/profile")
  // })
};
export function IndividualUpdate(props) {
  let navigate = useNavigate();
  let [Inputstate, changeInput] = useState({ value: props.value });

  return (
    <>
      <input
        type="text"
        value={Inputstate.value}
        id={props.id}
        onChange={(event) => changeInput({ value: event.target.value })}
      />
      <button
        className="bg-btn-suc font-normal text-white w-16 rounded ml-80"
        onClick={() => handleSubmit(Inputstate.value, props.id, navigate)}
      >
        Save
      </button>
    </>
  );
}

export default function Profile() {
  const [learner, setlearner] = useState<any>();
  const location = window.location;
  const fetchLeanerDetail = async () => {
    try {
      const result = await fetch(`${API_URL}/learner_detail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
      });
      if (!result.ok) {
        
        toast.error("Unable to get Learners Details");
        //  setlearner([]);
        return;
      }
      console.log(result);
      const data = await result.json();
      console.log(data);
      setlearner(data.learner);
    } catch (err) {
      toast.error(err.response.data.error);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLeanerDetail();
  }, []);

  if (!learner) {
    return <Loader />;
  }

  if (location.pathname === "/settings/profile") {
    return <DisplayProfile learner={learner} />;
  } else if (location.pathname === "/settings/editProfile") {
    return <EditProfile learner={learner} updateLearner={setlearner} />;
  } else {
    return <h1>Something went wrong</h1>;
  }
}

export function DisplayProfile({ learner }) {
  console.log(learner);

  if (!learner) {
    return <Loader />;
  }

  // let restrictedFields = [
  //   "_id",
  //   "valid_from",
  //   "valid_to",
  //   "extend_validity",
  // ];

  return (
    <>
      <div className="profile_view flex overflow-hidden">
        <FaUserAlt />
        <div className="flex flex-col items-center justify-center">
          <span className="ml-5 font-bold text-4xl self-center">
            {learner.learner_name}
          </span>
          <span className="ml-5 mt-2 font-medium text-base self-start">
            LMS_ID: {learner._id}
          </span>
        </div>
      </div>
      <div className="mt-10">
        {Object.keys(learner).map((key) => {
          return (
            <div key={key}>
              <div className="data_bar h-20 bg-white grid grid-cols-gap_int p-3 items-center text-xl font-medium border border-border-steam">
                <div>{key} :</div>
                <div className="text-ellipsis whitespace-nowrap">
                  <input
                    disabled
                    className="w-full"
                    type="text"
                    id={key}
                    value={
                      typeof learner[key] === "string" ||
                      typeof learner[key] === "number"
                        ? learner[key]
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export function Edit(learner) {
  let restrictedFields = ["_id", "valid_from", "valid_to", "extend_validity"];
  if (!learner) {
    return <Loader />;
  }

  return (
    <>
      <div className="profile_view flex overflow-hidden">
        <FaUserAlt />
        <div className="flex flex-col items-center justify-center">
          <span className="ml-5 font-bold text-4xl self-center">
            {learner.learner_name}
          </span>
          <span className="ml-5 mt-2 font-medium text-base self-start">
            LMS_ID: {learner._id}
          </span>
        </div>
      </div>
      <div className="mt-10">
        {Object.keys(learner).map((key) => (
          <div key={key}>
            <div className="data_bar h-20 bg-white grid grid-cols-gap_int p-3 items-center text-xl font-medium border border-border-steam">
              <div>{key} :</div>
              <div className="text-ellipsis whitespace-nowrap">
                <input
                  disabled={restrictedFields.includes(key)}
                  className="w-full"
                  type="text"
                  id={key}
                  value={
                    typeof learner[key] === "string" ||
                    typeof learner[key] === "number"
                      ? learner[key]
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        ))}
        <button className="bg-voilet font-normal text-white rounded w-full">
          EDIT
        </button>
      </div>
    </>
  );
}

export function Loader() {
  return (
    <div
      role="status"
      className="fixed left-1/2 right-1/2 top-1/2 bottom-1/2 transform-x-50 transform-y-50"
    >
      <svg
        aria-hidden="true"
        className="w-20 h-32 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
}